import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';
// import TUICallKit
import { TUICallKit } from '@tencentcloud/call-uikit-vue';


const  getUrlParams3 = function(name: string){
	var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return '';

}

const SDKAppID = 1400695683; // Your SDKAppID
const secretKey = '47687928c6e79f7f3049270f70aa482c50fb66c07c44181fd9592b3109887286'; //Your secretKey
const chatkey = getUrlParams3('chatkey');
// 根据key调用一下网络请求获取聊天的双方信息
// fetch('https://api.buygogo.com/api/getChatInfo?key=' + chatkey).then(res => {
//   res.json().then(res => {
//     console.log(res);
//   });
// });
// fetch('http://api.buygogo.com/api/getChatInfo?key=' + chatkey).then(res => {
//   res.json().then(res => {
//     console.log(res);
//   });
// });
const is_mobile = getUrlParams3('is_mobile');
sessionStorage.setItem('is_mobile', is_mobile || '0');

const is_customer = getUrlParams3('is_customer');
sessionStorage.setItem('is_customer', is_customer || '0');
const env1 = getUrlParams3('env');
if ("demo" == env1){
  sessionStorage.setItem('apiServer', 'https://api.test.buygogo.com');
  // sessionStorage.setItem('apiServer', 'http://localhost:8061');
}
else {
  sessionStorage.setItem('apiServer', 'https://api.buygogo.com');
}
const serverUrl = `${sessionStorage.getItem('apiServer')}/api/chat/getChatInfo`;
// const serverUrl = "demo" == env1 ? 'https://api.test.buygogo.com/api/chat/getChatInfo' : 'https://api.buygogo.com/api/chat/getChatInfo';

fetch(`${serverUrl}?key=` + chatkey).then(res => {
  res.json().then(res => {
    if (res && res.data){
      initSDK(res.data.from, 'C2C' + res.data.to);
    }
  });
});

function initSDK(userID: any, pid: any){
  sessionStorage.setItem('pid', pid);
  sessionStorage.setItem('uid', userID);
  // init TUIKit
  const TUIKit = TUICore.init({
    SDKAppID,
  });
  // TUIKit add TUIComponents
  TUIKit.use(TUIComponents);
  // TUIKit add TUICallKit
  TUIKit.use(TUICallKit);
  
  
  // login TUIKit
  TUIKit.login({
    userID: userID,
    userSig: genTestUserSig({
      SDKAppID,
      secretKey,
      userID,
    }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
  }).then(function(imResponse) {
    console.log(imResponse.data); // 登录成功
  });
  
  createApp(App).use(TUIKit).use(router).mount('#app')
}



