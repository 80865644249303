import { createRouter, createWebHistory  } from "vue-router";

const routes =[
    // {
    //     path: '/',
    //     redirect: '/action'
    // },
    {
        path: '/action',
        component: ()=>{
            return import('../components/Chat.vue')
        },
        
    },
    {
        path: '/test',
        component: ()=>{
            return import('../components/HelloWorld.vue')
        },
        
    }
];
const router = createRouter({
    // 配置选项
    history:createWebHistory(),
    routes:routes
});

export default router;