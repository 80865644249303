<template>
  <div class="home-TUIKit-main">
    <div
      :class="mobile ? 'conversation-h5' : 'conversation'"
      v-show="!mobile || currentModel === 'conversation'"
    >
      <!-- <TUISearch class="search" />  -->
      <TUIConversation @current="handleCurrentConversation" />
    </div>
    <div class="chat" v-show="!mobile || currentModel === 'message'">
      <TUIChat>
        <!-- <h1>欢迎使用腾讯云即时通信IM</h1> -->
      </TUIChat>
    </div>
    <!-- TUICallKit 组件：通话 UI 组件主体 -->
    <TUICallKit
      :class="!showCallMini ? 'callkit-drag-container' : 'callkit-drag-container-mini'"
      :allowedMinimized="true"
      :allowedFullScreen="false"
      :beforeCalling="beforeCalling"
      :afterCalling="afterCalling"
      :onMinimized="onMinimized"
      :onMessageSentByMe="onMessageSentByMe"
    />
  </div>

</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { TUIEnv } from "./TUIKit/TUIPlugin";
import { handleErrorPrompts } from "./TUIKit/TUIComponents/container/utils";
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

// onMounted(()=>{
//   window.addEventListener('resize', reload)
// })
// const reload = () => {
//   const SDKAppID = 1231; // Your SDKAppID
//   const secretKey = 'aaaa'; //Your secretKey
//   const userID = 'xiaoyu2'; // User ID

//   const TUIKit = TUICore.init({
//     SDKAppID,
//   });
//   TUIKit.login({
//     userID: userID,
//     userSig: genTestUserSig({
//       SDKAppID,
//       secretKey,
//       userID,
//     }).userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
//   }).then(function(imResponse:any) {
//     console.log(imResponse.data); // 登录成功
    
//   });
// }
export default defineComponent({
  name: "App",
  setup() {
    const data = reactive({
      env: TUIEnv(),
      mobile: sessionStorage.getItem('is_mobile') === '1',
      currentModel: "conversation",
      showCall: false,
      showCallMini: false,
    });

    // 隐藏loading
    const myLoading = document.getElementById("myloading");  
    if (myLoading) {  
      myLoading.style.display = 'none';
    }  

    // 解析地址栏里的URL,获取userId
    const userId = sessionStorage.getItem('pid');
    const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer;

    TUIServer.TUIConversation.getConversationProfile(userId).then((imResponse:any) => {
      
      TUIServer.TUIConversation.handleCurrentConversation(imResponse.data.conversation);
      if (data.mobile){
        const apiUrl = `${sessionStorage.getItem('apiServer')}/api/chat/welcome?account=${sessionStorage.getItem('uid')}`;
        console.log('apiUrl', apiUrl);
        fetch(apiUrl).then(res => {
        });
      }
      
    });

    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? "message" : "conversation";
    };
    // beforeCalling：在拨打电话前与收到通话邀请前执行
    const beforeCalling = (type: string, error: any) => {
      if (error) {
        handleErrorPrompts(error, type);
        return;
      }
      data.showCall = true;
    };
    // afterCalling：结束通话后执行
    const afterCalling = () => {
      data.showCall = false;
      data.showCallMini = false;
    };
    // onMinimized：组件切换最小化状态时执行
    const onMinimized = (
      oldMinimizedStatus: boolean,
      newMinimizedStatus: boolean
    ) => {
      data.showCall = !newMinimizedStatus;
      data.showCallMini = newMinimizedStatus;
    };
    // onMessageSentByMe：在整个通话过程内发送消息时执行
    const onMessageSentByMe = async (message: any) => {
      TUIServer?.TUIChat?.handleMessageSentByMeToView(message);
      return;
    };
    return {
      ...toRefs(data),
      handleCurrentConversation,
      beforeCalling,
      afterCalling,
      onMinimized,
      onMessageSentByMe,
    };
  },
});


</script>
 
<style lang="scss" scoped>
.home-TUIKit-main {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.search {
  padding: 12px;
}
.conversation {
  min-width: 285px;
  flex: 0 0 24%;
  border-right: 1px solid #f4f5f9;
}
.conversation-h5 {
  flex: 1;
  border-right: 1px solid #f4f5f9;
}
.chat {
  flex: 1;
  height: 100%;
  position: relative;
}
.callkit-drag-container {
  position: fixed;
  left: calc(50% - 25rem);
  top: calc(50% - 18rem);
  width: 50rem;
  height: 36rem;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.callkit-drag-container-mini {
  position: fixed;
  width: 168px;
  height: 56px;
  right: 10px;
  top: 70px;
}

</style>
